import React, { useCallback, useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, DatePicker, Radio } from 'antd';
import ptBR from 'antd/es/date-picker/locale/pt_BR';
import { FormItem, IconWithTooltip, Input, Select } from 'components';
import appConfigs from 'configs/app';
import { LEGAL_PERSON_TYPE, PHYSICAL_PERSON_TYPE } from 'configs/constants';
import { Can } from 'hooks/ability';
import { useContract } from 'hooks/fetch/useContract';
import { ThirdProviderEnum } from 'types/CreditRequest/enums/ThirdProvidersEnum';

import { RenderFormField } from '../RenderFormField';

interface Props {
  statusReadOnly: boolean;
  operationTypes: any[];
  form: any;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setDisabledCodeDataSul: React.Dispatch<React.SetStateAction<boolean>>;
  setDisabledProvider: React.Dispatch<React.SetStateAction<boolean>>;
  setTypePerson: React.Dispatch<React.SetStateAction<string>>;
  isFormVisible: boolean;
  handleChangeProfile: (value: any) => void;
  handleFindProvider: () => Promise<void>;
  typePerson: string;
  disabledCodeDataSul: boolean;
  disabledProvider: boolean;
  sustainabilityId: any;
  setVisibleRequestsContract: React.Dispatch<React.SetStateAction<boolean>>;
  isClone: boolean;
  isContractVisible: boolean;
  thirdProviders: ThirdProviderEnum[];
  setIsContractVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setRegistrationsNumbers: React.Dispatch<React.SetStateAction<string[]>>;
}

export const BlockForm = ({
  statusReadOnly,
  operationTypes,
  form,
  setLoading,
  setDisabledCodeDataSul,
  setDisabledProvider,
  setTypePerson,
  isFormVisible,
  handleChangeProfile,
  handleFindProvider,
  typePerson,
  disabledCodeDataSul,
  disabledProvider,
  sustainabilityId,
  setVisibleRequestsContract,
  isClone,
  isContractVisible,
  setIsContractVisible,
  setRegistrationsNumbers,
  thirdProviders,
}: Props) => {
  const { t } = useTranslation();
  const providerRef = useRef<any>();
  const { getContractByNumber } = useContract();

  const [haveWeekendOperations, setHaveWeekendOperations] = useState(true);
  const [selectedRegion, setSelectedRegion] = useState<string | undefined>(undefined);

  const shouldShowDatasul = thirdProviders?.some(provider => provider.toLowerCase() === 'datasul');
  const shouldShowSap = thirdProviders?.some(provider => provider.toLowerCase() === 'sap');

  const handleFindContract = useCallback(async () => {
    const formValues = form.getFieldsValue();

    if (!formValues.contract_number) {
      return;
    }
    const { contract_number } = formValues;

    setLoading(true);
    const response = await getContractByNumber(contract_number, false);
    setLoading(false);

    if (!response) {
      form.setFieldsValue({
        contract_number: '',
        doc_number: undefined,
        code_datasul: undefined,
        provider: undefined,
      });
      setDisabledCodeDataSul(true);
      setDisabledProvider(false);
    } else {
      const data = response;
      setDisabledCodeDataSul(true);
      setDisabledProvider(true);
      setVisibleRequestsContract(true);

      const isLegalPerson = data.ext_seller_cnpj?.length === 14;

      setTypePerson(isLegalPerson ? LEGAL_PERSON_TYPE : PHYSICAL_PERSON_TYPE);
      const document = isLegalPerson ? data.ext_seller_cnpj : data.ext_seller_cpf;

      form.setFieldsValue({
        requester_person_type: isLegalPerson ? LEGAL_PERSON_TYPE : PHYSICAL_PERSON_TYPE,
        doc_number: document || undefined,
        code_datasul: data.ext_issuer_code ? data.ext_issuer_code : undefined,
        provider: data.ext_seller_name ? data.ext_seller_name : undefined,
        product_id: data.product_id,
        harvest_id: data.harvest_id ?? '',
        region_id: data.region_id ?? '',
        subsidiary_id: data.subsidiary_id ?? '',
      });

      setRegistrationsNumbers(data.registrations?.map(registration => registration.number.toString()) ?? []);
    }
  }, []);

  const handleBlurDocNumber = () => handleFindProvider();

  const changeWeekendOperations = (event: any) => {
    const { value } = event.target;
    setHaveWeekendOperations(value);
    form.setFields([
      {
        errors: [],
        name: ['end_date'],
      },
      {
        errors: [],
        name: ['start_date'],
      },
    ]);
  };

  useEffect(() => {
    const weekendOperations = form.getFieldValue('is_weekend_operations');
    if (weekendOperations !== undefined) setHaveWeekendOperations(weekendOperations);
  }, [form.getFieldValue('is_weekend_operations')]);

  return isFormVisible ? (
    <>
      <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
        <FormItem rules={[{ required: true }]} label={t('pages.sustainability.form.operation')} name="operation_id">
          <Select options={operationTypes} disabled={statusReadOnly} />
        </FormItem>
      </Col>{' '}
      <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
        <FormItem
          name="check_contract_number"
          label={
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span>{t('pages.sustainability.form.check_contract_number')}</span>
              <span style={{ fontSize: 11 }}>{t('pages.sustainability.form.check_contract_number_details')}</span>
            </div>
          }
          rules={[
            {
              required: true,
              message: `'${t('pages.sustainability.form.check_contract_number')}' ${t(
                'pages.sustainability.form.required',
              )}`,
            },
          ]}
          initialValue
        >
          <Radio.Group buttonStyle="solid" disabled={statusReadOnly || sustainabilityId || isClone}>
            <Radio.Button onClick={() => setIsContractVisible(true)} value>
              {t('pages.sustainability.form.yes')}
            </Radio.Button>
            <Radio.Button
              onClick={() => {
                form.setFieldsValue({ contract_number: '' });
                setIsContractVisible(false);
              }}
              value={false}
            >
              {t('pages.sustainability.form.no')}
            </Radio.Button>
          </Radio.Group>
        </FormItem>
      </Col>
      {isContractVisible && (
        <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
          <FormItem
            label={
              <div>
                <span>{t('pages.sustainability.form.contract_number')}</span>
                <span style={{ fontSize: 11 }}>{` ${t('pages.sustainability.form.contract_number_details')}`}</span>
              </div>
            }
            name="contract_number"
            rules={[
              {
                required: true,
                message: `'${t('pages.sustainability.form.contract_number')}' ${t(
                  'pages.sustainability.form.required',
                )}`,
              },
            ]}
          >
            <Input
              maxLength={255}
              disabled={statusReadOnly || sustainabilityId || isClone}
              suffix={
                <Can I="contract.bynumber">
                  <IconWithTooltip
                    action="search"
                    className="icon-in-input-suffix"
                    title={t('pages.credit-request.form.contract-search')}
                    onClick={() => handleFindContract()}
                  />
                </Can>
              }
            />
          </FormItem>
        </Col>
      )}
      <RenderFormField
        fieldName="profile"
        onChange={handleChangeProfile}
        isDisabled={statusReadOnly || sustainabilityId || isContractVisible || isClone}
      />
      <RenderFormField
        fieldName="docNumber"
        onBlur={handleBlurDocNumber}
        isDisabled={statusReadOnly || sustainabilityId || isClone}
        typePerson={typePerson}
      />
      <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8} style={{ display: shouldShowDatasul ? '' : 'none' }}>
        <FormItem
          label={t('pages.sustainability.form.code_datasul')}
          name="code_datasul"
          style={{ display: shouldShowDatasul ? '' : 'none' }}
        >
          <Input
            disabled={disabledCodeDataSul || statusReadOnly || sustainabilityId || isContractVisible || isClone}
            maxLength={255}
          />
        </FormItem>
      </Col>
      <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8} style={{ display: shouldShowSap ? '' : 'none' }}>
        <FormItem
          label={t('pages.sustainability.form.cod_sap')}
          name="cod_sap"
          style={{ display: shouldShowSap ? '' : 'none' }}
        >
          <Input
            disabled={disabledCodeDataSul || statusReadOnly || sustainabilityId || isContractVisible || isClone}
            maxLength={255}
          />
        </FormItem>
      </Col>
      <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
        <FormItem label={t('pages.sustainability.form.provider')} name="provider" rules={[{ required: true }]}>
          <Input
            disabled={disabledProvider || statusReadOnly || sustainabilityId || isContractVisible}
            ref={providerRef}
            maxLength={255}
          />
        </FormItem>
      </Col>
      <RenderFormField fieldName="harvest" isDisabled={statusReadOnly || sustainabilityId || isClone} />
      <RenderFormField fieldName="commodity" isDisabled={statusReadOnly || sustainabilityId || isClone} />
      <RenderFormField
        rules={[{ required: true }]}
        fieldName="region"
        onChange={value => setSelectedRegion(value)}
        isDisabled={statusReadOnly || sustainabilityId || isClone}
      />
      <RenderFormField
        rules={[{ required: true }]}
        fieldName="subsidiary"
        regionId={selectedRegion}
        isDisabled={statusReadOnly || sustainabilityId || isClone}
      />
      <Col xs={12} sm={12} md={12} lg={8} xl={8} xxl={8}>
        <FormItem
          rules={[{ required: haveWeekendOperations }]}
          label={t('pages.sustainability.form.start_date')}
          name="start_date"
        >
          <DatePicker
            locale={ptBR}
            mode="date"
            format={appConfigs.formatDate}
            disabledDate={currentDate => form.getFieldValue('planting_start') > currentDate}
            disabled={statusReadOnly}
            allowClear
          />
        </FormItem>
      </Col>
      <Col xs={12} sm={12} md={12} lg={8} xl={8} xxl={8}>
        <FormItem
          rules={[{ required: haveWeekendOperations }]}
          label={t('pages.sustainability.form.end_date')}
          name="end_date"
        >
          <DatePicker
            locale={ptBR}
            mode="date"
            format={appConfigs.formatDate}
            disabledDate={currentDate => form.getFieldValue('start_date') > currentDate}
            disabled={statusReadOnly}
            allowClear
          />
        </FormItem>
      </Col>
      <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
        <FormItem
          name="is_weekend_operations"
          label={t('pages.sustainability.form.is_weekend_operations')}
          rules={[{ required: true }]}
          initialValue
        >
          <Radio.Group onChange={changeWeekendOperations} buttonStyle="solid" disabled={statusReadOnly}>
            <Radio.Button onClick={() => setIsContractVisible(true)} value>
              {t('pages.sustainability.form.yes')}
            </Radio.Button>
            <Radio.Button value={false}>{t('pages.sustainability.form.no')}</Radio.Button>
          </Radio.Group>
        </FormItem>
      </Col>
    </>
  ) : null;
};
